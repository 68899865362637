/* eslint-disable */

import $, { each } from 'jquery';
import Plyr from 'plyr';

const MOBILE_WIDTH = 767;
const APART_IMG_PATH = '/images/sholokhov/plans/apart-caption/';
const PLAN_IMG_PATH = '/images/sholokhov/plans/overall/';

const ready = function() {
  // smth like one-roomed_1
  let gCurrentApartId = getApartId();
  let gPlayerObject = {};

  let gCurrentCompareApartId = null;

  if (gCurrentApartId) {
    showPopup(gCurrentApartId, getPlaceId());
  }

  // functions
  function getApartId() {
    const currentQuery = location.search || '';
    return (currentQuery.match(/apartId=([^&]*)/) || [])[1];
  }
  function getPlaceId() {
    const currentQuery = location.search || '';
    return (currentQuery.match(/place=([^&]*)/) || [])[1];
  }
  function getSectionIdFromPlaceId(placeId) {
    const tempString = placeId.split('_');
    return tempString[0];
  }

  function createPlayer(placeId, apartId) {
    destroyPlayer(apartId);

    gPlayerObject[apartId] = [];
    const $videoObj = $('#' + apartId + '_' + placeId + ' .popup-plyr-player');

    $videoObj.each(index => {
      gPlayerObject[apartId].push(
        new Plyr(
          '#' + apartId + '_' + placeId + ' .popup-plyr-player.player' + index,
          {
            disableContextMenu: true,
            hideControls: true,
            controls: ['play-large', 'progress', 'current-time', 'fullscreen'],
          }
        )
      );
    });
  }
  function destroyPlayer(apartId) {
    if (gPlayerObject[apartId] && gPlayerObject[apartId].length)
      for (let player of gPlayerObject[apartId]) {
        player.destroy();
      }
  }

  function showFloorBg(apartId, bgIdsArr, placeId) {
    $('#' + apartId + '_' + placeId + ' .apart').removeClass('visible');
    if (bgIdsArr[0] && bgIdsArr.length) {
      bgIdsArr.forEach(el => {
        $('#' + apartId + '_' + placeId + ' .apart.' + el) &&
          $('#' + apartId + '_' + placeId + ' .apart.' + el).addClass(
            'visible'
          );
      });
    }
  }

  function showPosition(apartId, position, placeId) {
    $(' #' + apartId + '_' + placeId + ' .apartment-position')
      .removeClass()
      .addClass('apartment-position ' + position);
  }

  function calculateFixedValue(parentTop, parentHeight, shift) {
    return window.innerHeight - parentTop - parentHeight * 0.7 - 0.5 * shift;
  }

  function setItitialNavSettings() {
    if (!gCurrentCompareApartId) {
      let $currentFixedNav = $('#' + gCurrentApartId + ' .js-fixed-nav');
      $currentFixedNav.parent().height($currentFixedNav.height());

      const currentParentTop = $currentFixedNav
        .parent()
        .get(0)
        .getBoundingClientRect().top;
      const shift = parseInt($('body').css('fontSize')) * 1.4;
      const parentHeight = $currentFixedNav.parent().innerHeight();
      const value = calculateFixedValue(currentParentTop, parentHeight, shift);

      if (value < 0 && !$currentFixedNav.hasClass('fixed')) {
        $currentFixedNav.addClass('fixed');
      }
    }
  }

  function showPopup(apartId = gCurrentApartId, placeId = '') {
    if ($('#' + apartId).length) {
      // show current apart
      $('.sh__apartment-popups').addClass('visible');
      $('.sh__apartment-popup:not(.compared)').removeClass('active');
      $('#' + apartId).addClass('active');
      $('body').addClass('overflow-hidden');

      //hide small compare popup
      if (gCurrentCompareApartId) {
        $('.sh__compare-popup').removeClass('visible');
      }

      // get data about first section
      let $switchElems = $('#' + apartId + ' .js-switch-apart');
      let $currentSwitchElem = $(
        '#' + apartId + ' .js-switch-apart[data-place-id="' + placeId + '"]'
      ).first();

      if (!placeId || !$currentSwitchElem.length) {
        $currentSwitchElem = $switchElems.first();
        placeId = $currentSwitchElem.data('placeId');
        if (gCurrentCompareApartId != apartId) {
          window.history.pushState(
            null,
            '',
            location.pathname + '?apartId=' + apartId + '&place=' + placeId
          );
        }
      }
      const bgIdsArr = ($currentSwitchElem.data('bgIds') + '').split(', ');
      const currentSection = getSectionIdFromPlaceId(placeId);

      // show current floor
      $switchElems.removeClass('active');
      $currentSwitchElem.addClass('active');

      // show current section
      $('#' + apartId + ' .js-floors').removeClass('active');
      $(
        '#' + apartId + ' .js-floors[data-section="' + currentSection + '"]'
      ).addClass('active');
      // show info about current floor
      $('#' + apartId + ' .sh__apartment-popup-section').removeClass('visible');
      $('#' + apartId + '_' + placeId).addClass('visible');

      showFloorBg(apartId, bgIdsArr, placeId);
      createPlayer(placeId, apartId);
      setItitialNavSettings();
    }
  }

  function closePopup() {
    // console.log('close');
    $('.sh__apartment-popups').removeClass('visible');
    $('.sh__apartment-popup').removeClass('active');
    $('body').removeClass('overflow-hidden');

    if (getApartId()) {
      window.history.pushState(null, '', location.pathname);
    }

    if (gCurrentCompareApartId) {
      renderComparePopover();
    }

    destroyPlayer(gCurrentApartId);
    destroyPlayer(gCurrentCompareApartId);
    gCurrentApartId = null;
    // gPlayer.destroy();
  }

  function renderComparePopover() {
    // get data from html
    const apartTitle = $(
      '#' + gCurrentCompareApartId + ' .sh__apartment-popup-header h3'
    ).text();
    const apartAreaText = $(
      '#' + gCurrentCompareApartId + ' .area-text'
    ).text();
    const apartImgClass = $(
      '#' + gCurrentCompareApartId + ' .apartment-plan-big img'
    ).attr('class');

    let sectionsArr = [];
    $('#' + gCurrentCompareApartId + ' .nav-wrap .section').each(function() {
      let $section = $(this)
        .find('.section-caption')
        .first();

      const section = $section
        .text()
        .replace(/\./g, '')
        .match(/\d+/)
        .toString();

      let floorsArr = [];

      $(this)
        .find('.sh__popup-nav')
        .each(function() {
          floorsArr.push(
            $(this)
              .text()
              .match(/\d+/)
              .toString()
          );
        });
      const floors = floorsArr.join(', ');

      sectionsArr.push({
        section,
        floors,
      });
    });

    // add content
    $('.sh__compare-popup .js-compare-title').text(apartTitle);
    $('.sh__compare-popup .js-compare-area').text(apartAreaText);
    $('.sh__compare-popup .js-compare-img')
      .attr(
        'src',
        `/images/sholokhov/plans/apart-caption/${gCurrentCompareApartId}.png`
      )
      .removeClass()
      .addClass('js-compare-img ' + apartImgClass);

    $('.sh__compare-popup .js-compare-plan .compare-plan-bg').removeClass(
      'visible'
    );
    $('.sh__compare-popup .js-compare-table tbody').empty();

    sectionsArr.forEach(elem => {
      $(
        `.sh__compare-popup .js-compare-plan .compare-plan-bg.s_${elem.section}`
      ).addClass('visible');
      $('.sh__compare-popup .js-compare-table tbody').append(
        `<tr><td>${(elem.section / 10).toFixed(1)}</td><td>${
          elem.floors
        }</td></tr>`
      );
    });

    $('.sh__compare-popup').addClass('visible');
  }

  function setNewApart(newApartId) {
    window.history.pushState(
      null,
      '',
      location.pathname + '?apartId=' + newApartId
    );
    gCurrentApartId = newApartId;
  }

  function openNeighborPopup(compareApartId) {
    // console.log(op);
    // check next & prev neighbors
    let $neighborApart = $('#' + compareApartId).prev();
    if (!$neighborApart.length) {
      $neighborApart = $('#' + compareApartId).next();
    }
    // get neighbor id
    const neibourApartId = $neighborApart.attr('id');

    setNewApart(neibourApartId);
    showPopup();
    // remove fixed nav mode
    $('#' + neibourApartId + ' .js-fixed-nav').removeClass('fixed');
    $('#' + neibourApartId + ' .js-fixed-nav')
      .parent()
      .css('height', 'inherit');
  }

  function getNextApartId() {
    let $targetPopup = $('#' + gCurrentApartId).next();
    if (!$targetPopup.length) {
      $targetPopup = $('.sh__apartment-popup').first();
    }
    if (
      gCurrentCompareApartId &&
      $targetPopup.attr('id') == gCurrentCompareApartId
    ) {
      $targetPopup = $targetPopup.next();
      if (!$targetPopup.length) {
        $targetPopup = $('.sh__apartment-popup').first();
      }
    }

    return $targetPopup.attr('id');
  }

  function getPrevApartId() {
    let $targetPopup = $('#' + gCurrentApartId).prev();
    if (!$targetPopup.length) {
      $targetPopup = $('.sh__apartment-popup').last();
    }
    if (
      gCurrentCompareApartId &&
      $targetPopup.attr('id') == gCurrentCompareApartId
    ) {
      $targetPopup = $targetPopup.prev();
      if (!$targetPopup.length) {
        $targetPopup = $('.sh__apartment-popup').last();
      }
    }
    return $targetPopup.attr('id');
  }

  // handlers
  window.addEventListener('popstate', event => {
    // обработка перехода в браузере
    // console.log(`Перешли на адрес "${document.location}"`);
    gCurrentApartId = getApartId();
    gPlayerObject = {};
    gCurrentCompareApartId = null;

    if (gCurrentApartId) {
      showPopup(gCurrentApartId);
    } else {
      closePopup();
    }
  });

  $('.js-open-sh-pop').click(function(e) {
    e.preventDefault();
    const currentApartId = $(this).data('apart');

    setNewApart(currentApartId);

    if (gCurrentCompareApartId) {
      $('.sh__apartment-popups').addClass('sh__compare-mode');
      showPopup(gCurrentCompareApartId);
    }

    showPopup();
    if (gCurrentCompareApartId == gCurrentApartId) {
      openNeighborPopup(gCurrentCompareApartId);
    }
    if (gCurrentCompareApartId) {
      // remove fixed nav mode
      $('#' + currentApartId + ' .js-fixed-nav').removeClass('fixed');
      $('#' + currentApartId + ' .js-fixed-nav')
        .parent()
        .css('height', 'inherit');
    }
  });

  $('.js-sh-popup-close').click(function(e) {
    e.preventDefault();
    // check is this popup comparing
    let $comparePopup = $(this).closest('.sh__apartment-popup.compared');
    console.log($comparePopup);
    if ($comparePopup.length) {
      $('#' + gCurrentCompareApartId).removeClass('compared');
      $('#' + gCurrentCompareApartId).removeClass('active');
      $('.sh__apartment-popups').removeClass('sh__compare-mode');

      $('#' + gCurrentCompareApartId + ' .js-apart-compare')
        .text('Сравнить')
        .data('action', 'compare');

      gCurrentCompareApartId = null;
    } else {
      closePopup();
    }
  });

  $('.sh__apartment-popup-scroll').click(function(e) {
    if ($(e.target).hasClass('sh__apartment-popup-scroll')) {
      closePopup();
    }
  });

  $('.js-switch-apart').click(function(e) {
    e.preventDefault();
    // get next planId
    const currentPlaceId = $(this).data('placeId');
    const currentApartId = $(this)
      .closest('.sh__apartment-popup')
      .attr('id');

    const currentBgIdsArr = ($(this).data('bgIds') + '').split(', ');
    const currentPosition = $(this).data('apartPosition');
    const currentSection = getSectionIdFromPlaceId(currentPlaceId);

    // show current floor
    $('#' + currentApartId + ' .js-switch-apart').removeClass('active');
    $(this).addClass('active');

    // show current section
    $('#' + currentApartId + ' .js-floors').removeClass('active');
    $(
      '#' +
        currentApartId +
        ' .js-floors[data-section="' +
        currentSection +
        '"]'
    ).addClass('active');
    // show info about floor
    $('#' + currentApartId + ' .sh__apartment-popup-section').removeClass(
      'visible'
    );
    $(' #' + currentApartId + '_' + currentPlaceId).addClass('visible');

    showFloorBg(currentApartId, currentBgIdsArr, currentPlaceId);
    createPlayer(currentPlaceId, currentApartId);
    showPosition(currentApartId, currentPosition, currentPlaceId);

    if (gCurrentCompareApartId != currentApartId) {
      window.history.pushState(
        null,
        '',
        location.pathname +
          '?apartId=' +
          currentApartId +
          '&place=' +
          currentPlaceId
      );
    }

    // ftw
    if ($('#' + currentApartId + ' .js-fixed-nav').hasClass('fixed')) {
      $('.sh__apartment-popup-scroll').animate(
        {
          scrollTop: window.innerHeight - 50,
        },
        500
      );
    }
  });

  $('.js-switch-apart').mouseenter(function(e) {
    const hoverPosition = $(this).data('apartPosition');
    const currentApartId = $(this)
      .closest('.sh__apartment-popup')
      .attr('id');
    const currentPlanId = $(
      '#' + currentApartId + ' .js-switch-apart.active'
    ).data('placeId');

    showPosition(currentApartId, hoverPosition, currentPlanId);
  });

  $('.js-switch-apart').mouseleave(function(e) {
    const currentApartId = $(this)
      .closest('.sh__apartment-popup')
      .attr('id');
    const $activeSwitch = $('#' + currentApartId + ' .js-switch-apart.active');
    const currentPlanId = $activeSwitch.data('placeId');
    const currentPosition = $activeSwitch.data('apartPosition');

    showPosition(currentApartId, currentPosition, currentPlanId);
  });

  $('.sh__apartment-popup-scroll').scroll(function(e) {
    if (gCurrentApartId && !gCurrentCompareApartId) {
      let $fixedNav = $('#' + gCurrentApartId + ' .js-fixed-nav');
      const parentTop = $fixedNav
        .parent()
        .get(0)
        .getBoundingClientRect().top;
      const shift = parseInt($('body').css('fontSize')) * 1.3;
      const parentHeight = $fixedNav.parent().innerHeight();
      const value = calculateFixedValue(parentTop, parentHeight, shift);
      if (
        value < 0 &&
        !$fixedNav.hasClass('fixed') &&
        !$fixedNav.closest('.compared').length
      ) {
        $fixedNav.addClass('fixed');
      } else if ($fixedNav.hasClass('fixed') && value >= 0) {
        $fixedNav.removeClass('fixed');
      }
    }
  });

  $('.js-sh-popup-prev').click(function(e) {
    const newApartId = getPrevApartId();
    setNewApart(newApartId);
    showPopup();
  });
  $('.js-sh-popup-next').click(function(e) {
    const newApartId = getNextApartId();
    setNewApart(newApartId);
    showPopup();
  });

  $(window).resize(function(e) {
    if (gCurrentApartId) {
      setItitialNavSettings();
    }
  });

  $(document).keydown(function(e) {
    // if phsw open escape all key events
    if ($('.pswp.pswp--open').length) return;
    if (e.keyCode == 27 && gCurrentApartId) {
      // esc
      closePopup();
    } else if (e.keyCode == 37 && gCurrentApartId) {
      // left arrow
      const newApartId = getPrevApartId();
      setNewApart(newApartId);
      showPopup();
    } else if (e.keyCode == 39 && gCurrentApartId) {
      // right arrow
      const newApartId = getNextApartId();
      setNewApart(newApartId);
      showPopup();
    }
  });

  // compare
  $('.js-apart-compare').click(function(e) {
    if (window.innerWidth > MOBILE_WIDTH) {
      const action = $(this).data('action');
      if (action == 'compare') {
        // compare mode on
        gCurrentCompareApartId = gCurrentApartId;

        $('.js-apart-compare')
          .text('Сравнить')
          .data('action', 'compare');
        $(this)
          .text('Не сравнивать')
          .data('action', 'remove-compare');

        closePopup();

        // $('.sh__apartment-popups').addClass('sh__compare-mode');
        $('#' + gCurrentCompareApartId)
          .siblings()
          .removeClass('compared');
        $('#' + gCurrentCompareApartId).addClass('compared');

        // // open neibour popup
        // openNeighborPopup(gCurrentCompareApartId);

        // remove fixed nav
        $('#' + gCurrentCompareApartId + ' .js-fixed-nav').removeClass('fixed');
        $('#' + gCurrentCompareApartId + ' .js-fixed-nav')
          .parent()
          .css('height', 'inherit');
      } else if (action == 'remove-compare') {
        // compare mode off
        $('#' + gCurrentCompareApartId).removeClass('compared');
        $('#' + gCurrentCompareApartId).removeClass('active');
        $('.sh__apartment-popups').removeClass('sh__compare-mode');

        $(this)
          .text('Сравнить')
          .data('action', 'compare');

        gCurrentCompareApartId = null;
      }
    }
  });

  $('.sh__compare-popup').click(function(e) {
    // open neibour popup
    $('.sh__compare-popup').removeClass('visible');
    $('.sh__apartment-popups').addClass('sh__compare-mode');
    showPopup(gCurrentCompareApartId);
    openNeighborPopup(gCurrentCompareApartId);
  });

  $('.js-sh-compare-close').click(function(e) {
    e.stopPropagation();
    $('#' + gCurrentCompareApartId).removeClass('compared');
    $('.sh__apartment-popups').removeClass('sh__compare-mode');

    $('#' + gCurrentCompareApartId + ' .js-apart-compare')
      .text('Сравнить')
      .data('action', 'compare');

    gCurrentCompareApartId = null;

    $('.sh__compare-popup').removeClass('visible');
  });
};

$(document).ready(ready);
